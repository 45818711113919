@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #ffffff;
  color: #212121;
  height: 100vh;
}

#root,
.App,
.logged--screen>.container,
.container>.row,
.logged--screen,
.Single-posts,
.dashContent,
.Single-posts>.container,
.Single-posts>.container>.row {
  height: 100%;
}

:root {
  --mainColor: #e44300;
}

a {
  color: #e44300;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
}

.btn-google-login {
  margin-top: 55px;
  margin-bottom: 45px;
}

.btn-google-login button {
  outline: none;
  border: none;
  padding: 12px;
  width: 400px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #fff;
}

.padding-text-google {
  margin-left: 10px;
}

.Ideascab {
  color: var(--mainColor);
}

/* 
.sign-up {
  background: #fff;
  padding: 40px 40px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
} */

/* .main-wraper-login {
  width: 50%;
  background: #fff;
  padding: 40px 40px;
  margin: 2% auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
} */

.or-login-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.or-login-page hr {
  width: 100%;
  color: #A9B4CD;
  margin-left: 35px;
  margin-right: 35px;
}

.or-login-page p {
  color: #A9B4CD;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  color: #606D93 !important;
}

.css-r47a1p-MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-top: 20px !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-color: #DFE8F6 !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: #606D93 !important;
}

.checkbox-remember {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkbox-remember label {
  color: #212529;
}

.Forgot-pass p {
  color: var(--mainColor);
  font-weight: 500;
}

.login-btn-main button {
  height: 45px;
  border: 0px;
  outline: 0px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}

.sign-up-btn-login-page {
  margin-top: 22px;
}

.color-signUp-span a {
  color: var(--mainColor);
}


input:focus,
textarea:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

a:hover,
a {
  text-decoration: none;
}

.btn-primary {
  background: #e44300;
  border-radius: 50px;
  border: 1px solid #e44300;
  padding: 0 1.5rem 0.1rem;
  line-height: 36px;
  display: flex;
  align-items: center;
  outline: 0px;
  justify-content: center;
}


.btn-outline-primary {
  color: #e44300;
  border-radius: 50px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0.1rem;
  line-height: 36px;
  margin-left: 1rem;
}

.btn-outline-primary:hover {
  background: #e44300;
  border-color: #e44300;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: #212121;
  border: 1px solid #21212190;
  color: #ffffff;
}

.btn:focus-visible {
  border: 0;
  box-shadow: none;
}

.App {
  font-size: 15px;
  color: #212121;
  padding: 0;
  background-color: #ffffff;
}

.logged--sidebar {
  background: #ffffff;
  padding: 2rem 2rem 0 1rem;
  display: flex;
  flex-flow: column wrap;
  position: fixed;
  left: auto;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: auto;
  max-width: 100%;
}

.fixed--sidebar,
.sidebar--logOut {
  position: fixed;
  width: inherit;
  max-width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.logged--sidebar::-webkit-scrollbar-track,
.fixed--sidebar::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.logged--sidebar::-webkit-scrollbar,
.fixed--sidebar::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

.logged--sidebar::-webkit-scrollbar-thumb,
.fixed--sidebar::-webkit-scrollbar-thumb {
  background-color: #21212108;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  overflow-y: visible;
  overflow-x: visible;
}

.sidebar--follow {
  background: #21212108;
  padding: 1rem 0;
  margin-top: 2rem;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  position: relative;
  overflow: visible;
  height: auto;
  width: 100%;
  max-width: 100%;
}

.sidebar--follow:last-child {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.ideas--logo img {
  max-width: 200px;
  margin-left: 1.5rem;
}

.ideas--logo .logo--small {
  max-width: 80px;
}

.pro-sidebar {
  color: inherit;
  height: 100%;
  width: 100%;
  min-width: 100%;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}

.pro-sidebar>.pro-sidebar-inner {
  background: none;
  height: 100%;
  position: relative;
  z-index: 101;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #212121;
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  padding: 0.6rem 1.2rem;
  border-radius: 50px;
  margin-bottom: 0.5rem;
}

.pro-sidebar .pro-menu .post--idea .pro-inner-item, .pro-sidebar .pro-menu .post--idea .pro-item-content {
  display: block;
}

.pro-sidebar .pro-menu .post--idea a {
  color: #ffffff;
  background: #e44300;
  display: block;
  text-align: center;
}

.pro-sidebar .pro-menu a:hover {
  background: #21212110;
  color: #e44300;
}

.pro-sidebar .pro-menu .post--idea a:hover {
  background: #D74100;
  color: #ffffff;
}

.pro-sidebar .pro-menu a.active {
  color: #e44300;
  font-weight: 700;
}

.pro-sidebar .pro-menu a svg {
  vertical-align: middle;
  margin-right: 0.5rem;
  height: auto;
  display: inline-block;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  border-top: 0;
  padding-bottom: 1rem;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
  padding-bottom: 5rem;
}

.logged--sidebar .card {
  border: 0;
  flex-flow: row wrap;
  margin-top: 0;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  bottom: 0;
  background: #f9f4f4;
}

.pro-sidebar-footer .profile-img-follow span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.pro-sidebar-footer {
  position: relative;
}

.hover--tooltip {
  box-shadow: 0 4px 15px -7px rgba(0, 0, 0, 0.4);
  background: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 0.6rem 1rem;
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  left: 0;
  width: 90%;
  right: 0;
  margin: auto;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.hover--tooltip:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -20px;
}

.pro-sidebar-footer:hover .hover--tooltip {
  visibility: visible;
}

.hover--tooltip:hover {
  box-shadow: 0 4px 15px -10px rgba(0, 0, 0, 0.4);
  background: #ffffff;
}

.logged--sidebar .card:hover {
  background: #21212110;
}

.logged--sidebar .card-body {
  padding: 0 0 0 0.6rem;
}

.sidebar--follow .more--ideas .card-body {
  padding: 0;
}

.profile-img-follow .tooltip-inner {
  max-width: 100%;
  background-color: #ffffff;
  padding: 1rem;
}

.logged--sidebar .card-title {
  color: #212121;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logged--sidebar .card-text {
  margin: 0;
  font-size: 13px;
  color: #21212180;
  margin-top: 0;
}

.sidebar--follow .more--ideas .card-title {
  margin: 0 0 0.5rem;
}

.sidebar--follow .more--ideas .card-text {
  margin: 0 0 0.5rem;
}

.feed--listing {
  border-width: 0 1px;
  border-style: solid;
  border-color: transparent #21212120;
  padding: 1rem 1rem 0;
  height: 100%;
}

.feed--listing .feed--listing {
  border-width: 0;
  padding: 0;
}

form.post--search {
  padding-top: 1rem;
  max-width: 100%;
  position: relative;
}

.out--with--bottom .post--search {
  padding-top: 1rem;
  position: relative;
  width: 100%;
  max-width: 100%;
  background: none;
  z-index: 111;
}

.hashtag-searchbar {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 2;
}

.hashtag-suggestions {
  background-color: #ffffff;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.hashtag-suggestions ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hashtag-suggestions li {
  padding: 12px 18px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #212121;
  display: flex;
  align-items: center;
}

.hashtag-suggestions li:hover {
  background-color: #21212110;
}

.hashtag-suggestions li small {
  display: block;
  font-size: 85%;
  font-weight: normal;
  color: #21212180;
}

.hashtag-suggestions li svg {
  height: auto;
  width: 2.5rem;
  margin-right: 1rem;
}


.post--search .form-group {
  position: relative;
  margin-bottom: 1rem;
}

.post--search .form-group .search--icon {
  position: absolute;
  left: 15px;
  top: 12px;
  color: #21212180;
}

.post--search .form-group .close--icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 12px;
  color: #21212180;
}

.post--search .form-group input {
  padding-left: 2.5rem;
  height: 50px;
  background: #21212108;
  border: 0;
  border-radius: 50px;
  -webkit-border-radius: 50px;
}

.type--feed--post {
  background: #ffffff;
  padding: 25px;
  margin: 0 -15px 0;
  border: 0;
  border-top: 1px solid #21212120;
}

.type--feed--post--modal {
  border-top: 0;
}

.type--here,
.post--options {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 2rem;
}

.post--options {
  margin: 0;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}

.post--options .list-group {
  border: 0;
  flex-flow: row wrap;
}

.post--options .list-group label {
  cursor: pointer;
}

.post--options .list-group-item {
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0;
}

.post--options .list-group-item svg {
  height: auto;
  width: 22px;
  stroke: #21212180;
}

.user--avatar {
  margin-right: 1rem;
}

.user--avatar .name--initial {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  line-height: 40px;
  text-align: center;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  background: #21212170;
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: 600;
}

.user--avatar .nm-a {
  background: #3C6255;
}

.user--avatar .nm-b {
  background: #3C6255;
}

.user--avatar .nm-c {
  background: #6C00FF;
}

.user--avatar .nm-d {
  background: #0081C9;
}

.user--avatar .nm-e {
  background: #A31ACB;
}

.user--avatar .nm-f {
  background: #CD0404;
}

.user--avatar .nm-g {
  background: #4E6C50;
}

.user--avatar .nm-h {
  background: #820000;
}

.user--avatar .nm-i {
  background: #567189;
}

.user--avatar .nm-j {
  background: #B3005E;
}

.user--avatar .nm-k {
  background: #0F6292;
}

.user--avatar .nm-l {
  background: #635985;
}

.user--avatar .nm-m {
  background: #E96479;
}

.user--avatar .nm-n {
  background: #40513B;
}

.user--avatar .nm-o {
  background: #2E3840;
}

.user--avatar .nm-p {
  background: #3E54AC;
}

.user--avatar .nm-q {
  background: #2E4F4F;
}

.user--avatar .nm-r {
  background: #8D7B68;
}

.user--avatar .nm-s {
  background: #66347F;
}

.user--avatar .nm-t {
  background: #2D2727;
}

.user--avatar .nm-u {
  background: #4D4D4D;
}

.user--avatar .nm-v {
  background: #002B5B;
}

.user--avatar .nm-w {
  background: #408E91;
}

.user--avatar .nm-x {
  background: #245953;
}

.user--avatar .nm-y {
  background: #B8621B;
}

.user--avatar .nm-z {
  background: #19376D;
}

.type--here .type--comment {
  flex: 1 1;
}

.type--here .type--comment .form-control {
  border: 0;
  padding: 0;
  border-bottom: 1px solid #21212110;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.type--here textarea {
  border: 0;
  width: 100%;
  color: #21212190;
  resize: none;
}

.feed--cards .card {
  margin-top: 1rem;
  flex-flow: row wrap;
  border: 1px solid #21212120;
  padding: 25px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin: 0rem -15px 0;
  border-bottom: 0;
}

.feed--cards .card:hover {
  background: #21212108;
}

.feed--cards .card:last-child {
  border-bottom: 1px solid #21212120;
}

.feed--cards .card-body {
  padding: 0;
  flex: 1 1;
}

.feed--cards .list-group,
.sidebar--follow .more--ideas .list-group {
  flex-flow: row wrap;
}

.feed--cards .card-title {
  margin-bottom: 1rem;
  text-transform: lowercase;
}

.feed--cards .card-title::first-letter {
  text-transform: uppercase;
}

.feed--cards .card-subtitle {
  margin-bottom: 1rem;
  color: #21212180;
  font-size: 15px;
  font-weight: normal;
}

.feed--cards .card-subtitle span {
  margin-right: 2rem;
}

.feed--cards .card-subtitle span svg {
  vertical-align: top;
  margin-right: 0.3rem;
}

.feed--cards .card-subtitle span a {
  color: #e44300;
  text-decoration: none;
}

.feed--cards .card-link {
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  text-decoration: none;
  text-transform: lowercase;
  display: block;
}

.feed--cards .card-link::first-letter {
  text-transform: uppercase;
}

.feed--cards .list-group-item,
.sidebar--follow .more--ideas .list-group-item {
  border: 0;
  padding: 0;
  margin: 0 1rem 0 0;
  color: #21212195;
  font-size: 14px;
  border-radius: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
}

.feed--cards .list-group-item:last-child,
.sidebar--follow .more--ideas .list-group-item:last-child {
  margin: 0;
}

.feed--cards .list-group-item svg,
.sidebar--follow .more--ideas .list-group-item svg {
  height: auto;
  margin-right: 0.3rem;
  width: 18px;
  vertical-align: middle;
  stroke: #21212170;
}

.sidebar--follow .more--ideas .list-group-item svg {
  margin-top: -3px;
}

.feed--cards .more--button {
  border: 0;
  background: none;
  padding: 0;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
}

.feed--cards .more--button .btn-light {
  color: #e44300;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
  font-weight: 500;
}

.feed--cards .more--button .btn-light:hover,
.feed--cards .more--button .btn-light:focus {
  color: #DE3F06;
  background: none;
  outline: none;
  border: 0;
  border-bottom: 1px solid #DE3F06;
}

.sidebar--follow h3 {
  font-weight: 900;
  margin-bottom: 1rem;
  padding: 0 1.2rem;
  font-size: 22px;
}

.sidebar--follow h4 {
  padding: 0 1.2rem;
  font-size: 18px;
}

.sidebar--follow .card {
  padding: 0.8rem 1rem;
  margin: 0;
  position: relative;
  align-items: center;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #21212120;
}

.sidebar--follow .card:hover {
  background: none;
}

.sidebar--follow .card:last-child {
  margin: 0;
  border-bottom: 0;
}

.sidebar--follow .card .btn-primary {
  padding: 0.4rem 1rem;
  line-height: normal;
  font-size: 14px;
  background: #212121;
  border: 1px solid #212121;
}

.sidebar--Login {
  position: relative;
  height: auto;
  overflow-y: visible;
  width: 100%;
  max-width: 100%;
}

.sidebar--Login .btn {
  line-height: 35px;
  padding: 0.5rem 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar--Login .btn svg {
  margin-right: 0.5rem;
}

.out--with--bottom,
.feed--listing--loggedOut {
  padding-bottom: 70px;
}

.sidebar--logOut {
  position: fixed;
  width: 310px;
  max-width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.sidebar--logOut::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.sidebar--logOut::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

.sidebar--logOut::-webkit-scrollbar-thumb {
  background-color: #21212108;
}

.bottom--bar {
  background: #e44300;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 111;
}

.bottom--bar .btn {
  margin: 0 0.4rem;
  padding: 0.4rem 1rem;
}

.feed--listing--loggedOut .comment--user {
  border: 0;
  border-bottom: 1px solid #21212120;
  margin: 0;
  flex-flow: row wrap;
  display: flex;
  align-items: flex-start;
}

.feed--listing--loggedOut .comment--user:last-child {
  border: 0;
  border-bottom: 0;
}

.feed--listing--loggedOut .comment--user .comment--user:last-child {
  border: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.feed--listing--loggedOut .comment--user .card-body {
  padding: 0 0 0 1rem;
  flex: 1 1;
}

.feed--listing--loggedOut .comment--user .card-title {
  margin: 0;
  color: #212121;
  font-weight: 500;
  font-size: 18px;
}

.feed--listing--loggedOut .comment--user .card-text {
  margin: 0;
  font-size: 14px;
  color: #21212180;
}

.feed--listing--loggedOut .comment--box .card-body {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #21212120;
  display: flex;
  flex-flow: column wrap;
}

.feed--listing--loggedOut .comment--box button {
  margin-left: auto;
}

.feed--listing--loggedOut .comment--box textarea {
  padding: 1rem;
  border: 0;
  resize: none;
  background: #D9D9D930;
  margin-bottom: 1rem;
}

.feed--cards .list-group-item strong {
  margin-right: 0.2rem;
}

/*-=-=-=-Vipin Css-=-=-=-*/

.btn-google-login {
  margin-top: 55px;
  margin-bottom: 45px;
}

.btn-google-login button {
  outline: none;
  border: none;
  padding: 12px;
  width: 400px;
  max-width: 100%;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #fff;
}

.btn-google-login button:hover {
  background: #21212120;
}

.padding-text-google {
  margin-left: 10px;
}

.Ideascab {
  color: var(--mainColor);
}

.main-wraper-login {
  text-align: center;
}

.or-login-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.or-login-page hr {
  width: 100%;
  color: #A9B4CD;
  margin-left: 35px;
  margin-right: 35px;
}

.or-login-page p {
  color: #A9B4CD;
  margin: 0.5rem 0;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  color: #606D93 !important;
}

.css-r47a1p-MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-top: 0 !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-color: #DFE8F6 !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: #606D93 !important;
}

.checkbox-remember {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkbox-remember label {
  color: #212529;
}

.Forgot-pass p {
  color: var(--mainColor);
  font-weight: 500;
}

.login-btn-main button {
  background-color: var(--mainColor);
  width: calc(50% - 40px);
  margin: auto;
  margin-top: 25px;
  border-radius: 50px;
  border: 0;
}

.login-btn-main button:hover {
  background-color: #212121;
  border: 0;
}

.sign-up-btn-login-page p {
  color: #212529;
}

.sign-up-btn-login-page {
  margin-top: 22px;
}

.color-signUp-span {
  color: var(--mainColor) !important;
  cursor: pointer;
}

.sign-up {
  text-align: left !important;
}

.sign-up p {
  color: #212529;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
}

.Privacy-text-sign-up p {
  font-size: 14px;
  margin-bottom: 0px;
  color: #212529;
}

.Date-of-birth h4 {
  color: #606D93;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin: 0;
}

.Date-of-birth p {
  font-size: 13px;
  margin-bottom: 20px;
}

.Date-of-birth {
  margin-top: 11px;
}

.date-picker {
  display: flex;
  align-items: baseline;
}

.date-picker p {
  font-size: 16px;
  width: 26%;
  margin-right: 10px;
}

.date-picker input[type="text"] {
  border: 1px solid #DFE8F6 !important;
  padding-left: 10px;
  height: 40px;
  width: 50%;
  border-radius: 5px;
}

.error-message {
  color: red;
  font-size: 12px;
}

/* 
.otp-Section {
  width: 46%;
  margin: 8% auto;
} */

.otp-Section form.digit-group {
  margin: 0 auto 0.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.error-massege {
  color: red;
  display: block;
  font-size: 13px;
  text-align: left;
  padding-left: 1.5rem;
  padding-top: 0.1rem;
}

.date-picker input.form-control {
  width: 120%;
  height: 42px;
}

.auther-name {
  color: #DE3F06 !important;
  text-transform: capitalize;
}

.modal-lg,
.modal-xl {
  --bs-modal-width: 700px;
}

.modal-content {
  width: 90%;
}

.modal-body {
  width: 100%;
  max-width: 70%;
  margin: auto;
  padding: 2rem 1rem;
}

.title-post-feed .card-link a, span{
  cursor: pointer;
}

.title-post-feed, .single--post--content {
  display: flex;
  justify-content: space-between;
}

.css-kzv9dm {
  width: 100% !important;
}

.css-i44wyl {
  width: 100% !important;
}

label+.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  margin-top: 8px !important;
}

.MuiFormControl-root.css-kzv9dm {
  margin-left: 0px !important;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl {
  width: 100% !important;
}

.MuiFormControl-root.css-kzv9dm {
  width: 100%;
  margin-left: 0px !important;
}

.card-link a {
  color: #212121 !important;
}

.profile-img-follow img {
  width: 37px;
  height: 37px;
}

.poll-img img {
  width: 20px;
  height: 20px;
}

.submit-btn-comment {
  display: flex;
  justify-content: end;
}

.mb-3.border-commet {
  /* border: 1px solid #dadada; */
  padding: 18px;
  margin-top: 10px;
}

.comment-img img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
}

.comment-img a {
  display: flex;
  align-items: center;
}

.comment-img p {
  display: flex;
  align-items: center;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.comment-img p svg {
  width: 18px;
  height: auto;
  margin-right: 0.3rem;
}

.comment-img h4 {
  font-size: 16px;
  margin: 0;
}

.text-comment {
  color: #606D93;
  font-size: 15px;
  padding-left: 65px;
}

.text-comment .accordion {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.comment-section-main {
  margin-top: 30px;
  position: relative;
  background: #21212108;
  padding: 1.2rem;
  border-radius: 10px;
  border: 0;
  flex: 0 0 100%;
  /* display: none; */
}

.comment-section-main:last-child {
  margin-bottom: 0;
}

.auther-name-commet {
  text-transform: capitalize;
}

.comment-img .dropdown {
  position: absolute;
  right: 10px;
}

.comment-img .dropdown-toggle,
.comment-img .btn:first-child:active {
  background: none;
  border: 0;
  padding: 0;
  color: #21212160;
}

.comment-img .btn.show {
  background: none;
  border: none;
  color: #21212190;
}

.comment-img .dropdown-toggle::after {
  display: none;
}

.comment-img .dropdown-toggle svg {
  width: 28px;
  height: auto;
}

.comment-img .dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  background: #ffffff;
  padding: 0;
  min-width: fit-content;
  border: 1px solid #21212110;
}

.delet-comment {
  font-size: 16px;
  color: var(--mainColor);
  cursor: pointer;
  margin-top: 0;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.8rem;
}

.delet-comment svg {
  margin-right: 0.2rem;
  font-size: 18px;
}

.comment-reply {
  margin-bottom: 14px;
}

.accordion-header-main svg {
  height: auto;
  margin-right: 0.3rem;
  width: 18px;
  vertical-align: middle;
  stroke: #21212170;
}

.accordion p svg {
  height: auto;
  width: 20px;
  vertical-align: middle;
  stroke: #21212170;
  margin-top: -2px;
}

.accordion-body-main .mb-3.border-commet {
  padding: 8px !important;
}

.likebtn svg {
  cursor: pointer;
}

.tabgmain-wraper p {
  color: var(--mainColor);
  font-size: 16px;
  font-weight: 600;
}

.loader-main-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.btn-upvoat {
  margin-right: 10px;
}

.border-top .btn-outline-primary {
  width: 130px;
}

.border-top .btn-primary {
  width: 141px;
}

.singlePost--search {
  padding-bottom: 0;
  padding-top: 1rem;
}

.singlePost--search .arrow--back {
  font-size: 30px;
}

.list-icons-main {
  margin-left: 0;
}

.accordion-header-main {
  cursor: pointer;
  font-size: 14px;
  color: #21212195;
  display: flex;
  align-items: center;
}

.accordion-body-main {
  margin-top: 1rem;
  flex: 0 0 100%;
}

.accordion-body-main:empty {
  display: none;
}

.post-form textarea {
  width: 100%;
  margin-top: 20px;
}

.tabgmain-wraper {
  display: flex;
  flex-flow: wrap;
}

.tabgmain-wraper p {
  margin-right: 7px;
}

textarea.highlight {
  background-color: yellow;
}

.nested-comment {
  margin-left: 0;
  margin-bottom: 1rem;
  background: #9c9c9c10;
  border: 0;
  padding: 1rem;
  border-radius: 10px;
}

.nested-comment:last-child {
  margin-left: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.nested-comment .comment-img {
  display: flex;
}

.nested-comment p {
  padding-left: 55px;
}

.nested-comment .comment-img img {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}

.nested-comment .comment-img h4 {
  font-size: 15px;
}

.nested-comment .comment-img p {
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 15px;
  margin-top: 5px;
}

.highlighted-keyword {
  color: var(--mainColor);
  font-weight: 600;
}

.single-post {
  margin-top: 0px;
}

.modal {
  z-index: 999999;
}

.modal-header {
  border-bottom: 0;
  padding: 0;
}

.modal-header .btn-close {
  position: absolute;
  z-index: 111;
  right: 1rem;
  top: 1rem;
}

.login-page-main-s .form-control {
  height: 55px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding-left: 1.5rem;
}

.login-page-main-s .form-select {
  height: 60px;
  padding: 1rem;
}

.form-floating>label {
  padding-left: 1.5rem;
}

.closemenu {
  display: none;
}

.post--search .close--icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 12px;
  color: #21212180;
}

.css-zrqpq1 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border: 1px solid #b6b6b6;
  height: 3.6em !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 50px !important;
  padding-left: 1.5rem !important;
}

.css-zrqpq1::before {
  border-bottom: 0px !important;
}

.css-mqt2s5 {
  margin-top: 11px;
  margin-left: 19px;
}

.css-zrqpq1:hover:not(.Mui-disabled, .Mui-error)::before {
  border-bottom: 0px !important;
}

.logged-out-searchbar {
  position: absolute;
  top: 13%;
  width: 30%;
  left: 40%;
  /* right: 0; */
  z-index: 9999;
}

.page--header {
  background: #ffffff;
  padding: 0.5rem 0;
  background: #e44300;
}

.page--header .navbar-expand-lg .navbar-nav .nav-link {
  color: #ffffff;
  border-bottom: 2px solid #ffffff40;
  padding: 0;
  padding-bottom: 0.3rem;
  margin: 0 0.5rem;
}

.page--header .navbar-expand-lg .navbar-nav .active {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.page--banner {
  background: url('../src/assets/images/businessman-logging-his-tablet.jpg') no-repeat top center;
  background-size: cover;
  padding: 5rem 0;
  position: relative;
}

.page--banner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #212121;
  opacity: 0.5;
}

.page--banner h1 {
  font-size: 6rem;
  font-weight: 900;
  position: relative;
  color: #ffffff;
}

.page--content {
  padding: 3rem 0;
}

.page--footer {
  text-align: center;
  border-top: 1px solid #21212120;
  padding-top: 1rem;
}

.profile--box .card {
  padding: 2rem 1rem 0;
  border: 0;
  border-radius: 0;
  border-left: 1px solid #21212120;
  border-right: 1px solid #21212120;
  display: flex;
  flex-flow: row wrap;
}

.profile--page .feed--cards .card {
  margin: 0;
  border: 1px solid #21212120;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1.2rem;
}

.profile--page .feed--cards .card-title {
  margin: 0;
  margin-bottom: 1rem;
  text-transform: none;
}

.profile--box .card-body {
  padding: 0 1rem 0 0;
}

.profile--page .card .list-group-item {
  border: 1px solid #C4C4C4;
  padding: 0.5rem 1rem;
  margin: 0 1rem 0 0;
  color: #e44300;
  font-size: 16px;
  min-width: 200px;
  text-align: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
}

.profile--box .card label {
  cursor: pointer;
  background: #21212120;
  border-radius: 100px;
  padding: 0.3rem;
  position: relative;
  overflow: hidden;
}

.profile--box .card label img {
  max-width: 130px;
}

.profile--box .card .button--group button {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0.2rem 1rem;
  width: 100%;
}

.profile--box .card .button--group button svg {
  margin-right: 0.5rem;
  height: auto;
  width: 20px;
}

.profile--box .card .button--group .btn-light {
  background: none;
  border: 0;
  color: #e44300;
  font-weight: 700;
}

.profile--box .card .button--group .btn-light:hover,.profile--box .card .button--group .btn-light:focus, .profile--box .card .button--group .btn-light:active {
  border: 0;
}

.profile--box .card .card-title {
  margin: 0;
}

.profile--modal .modal-dialog {
  max-width: 650px;
}

.profile--modal .profile--upload {
  cursor: pointer;
  position: relative;
  background: #21212120;
  border-radius: 100px;
  padding: 0.3rem;
  margin: auto;
  overflow: hidden;
}

.profile--modal .profile--upload span,
.profile--box .card label span {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100px;
  font-size: 2rem;
  transition: all 0.5s ease-in-out;
}

.profile--modal .profile--upload:hover span,
.profile--box .card label:hover span {
  background: rgba(0, 0, 0, 0.3);
  transform: scale(1.2);
}

.profile--modal .profile--upload img {
  max-width: 160px;
}

.profile--modal button {
  margin-left: auto;
}

.comment--modal .modal-dialog {
  max-width: 650px;
}

.comment--modal .modal-body,
.comment--modal .modal-content {
  max-width: 100%;
}

.comment--modal .modal-body {
  padding: 2rem 1rem;
}

.comment--modal .card {
  border: 0;
  flex-flow: row wrap;
}

.comment--modal .card-body {
  padding-top: 0;
  flex: 1 1;
  font-size: 15px;
}

.comment--modal .card-body .auther-name {
  color: #212121 !important;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.comment--modal form {
  margin-top: 1rem;
}

.comment--modal form img {
  max-width: 45px;
  margin-right: 1rem;
}

.comment--modal form .post--media--upload img, .comment--modal form .post--media--upload video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 15px;
  object-fit: cover;
  object-position: center;
}

.cropper--modal {
  background: rgba(0,0,0,0.85);
}

.cropper--modal .modal-dialog {
  max-width: 800px;
}

.formTop.p-5.shadow-lg.rounded {
  padding-top: 100px !important;
  padding-bottom: 101px !important;
}

.fade.modal-backdrop.show {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.forget-pass-model {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

.form-floating {
  width: 100% !important;
  margin-top: 20px;
}

.comment-popup img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}

.comment-imgg img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}

.comment-imgg {
  display: flex;
}

.auther-name-commet {
  margin-top: 7px;
  font-size: 15px;
}

.comments-postfeed {
  height: 300px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 20px;
}

.hash-post-listing {
  padding-bottom: 100px !important;
}

.main-view-hash-post {
  padding-bottom: 80px !important;
}

.like-hash-post-main-view svg {
  margin-left: 42px;
}

.link-comment-icon {
  color: #21212195 !important;
}

.card-title h5 a {
  text-transform: capitalize !important;
}

.cross-icon {
  text-align: end;
  margin-top: 10px;
  margin-right: 10px;
  color: var(--mainColor);
}

.forget-pass {
  text-align: center;
  font-size: 10px;
  margin-top: 20px;
}

.forget-pass h5 {
  font-size: 15px;
  cursor: pointer;
}

.model-forget-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

h4.results-not-found-hash {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
}

.logged--screen .feed--cards .list-group-item:last-child {
  margin-right: 10px;
}

.feed--listing .feed--listing {
  width: 100%;
}

.hashtag-suggestions li a {
  color: #212121 !important;
}

.comment-top-ideas {
  margin-right: 9px;
}

.comment-marging p {
  margin-bottom: 0px !important;
}

.delete-comment {
  display: flex;
  align-items: center;
  padding: 8px;
  color: var(--mainColor);
  cursor: pointer;
}

.upvoat-main-wraper {
  display: flex;
  justify-content: flex-end;
}

.message-alert p {
  font-weight: 600;
  color: var(--mainColor);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  background-color: #D74100;
  border-color: transparent;
}

.post--media--grid, .post--feed--grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -2px;
}

.post--media--upload, .post--feed--media {
  position: relative;
  flex: 1 1;
  padding: 0 2px;
}

.post--feed--media {
  margin-top: 1rem;
}

.post--media--upload .post--media--close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: #999999;
  line-height: 30px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post--media--upload .post--media--close svg {
  fill: #ffffff;
}

.post--media--upload .post--media--close:hover {
  background: #21212190;
}

.post--media--upload .post--media--edit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #21212180;
  color: #fff;
  border-radius: 50px;
  padding: 0.2rem 1rem;
  cursor: pointer;
}

.post--media--upload .post--media--edit:hover {
  background: #212121;
}

.post--media--upload img, .post--feed--grid img,
.post--media--upload video, .post--feed--media video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 15px;
  object-fit: cover;
  object-position: center;
}

.profile--page .speci--card .list-group-item {
  padding: 0.5rem;
  margin: 0 10px 10px 0;
  color: #141414;
  font-size: 15px;
  min-width: 150px;
}

.profile--page .card ul {
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.profile--page .card ul li {
  margin: 0;
  margin-bottom: 0rem;
  list-style: disc;
}

.profile--page .card ul li:last-child, .profile--page .card p:last-child {
  margin-bottom: 0;
}

.profile--page .card p.card-text ul:last-child {
  margin: 0;
}

.profile--page .feed--cards .rate--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile--page .card-title button svg {
  margin-right: 0.5rem;
  height: auto;
  width: 20px;
}

.profile--page .card-title .accordion-item {
  background-color: transparent;
  border: 0;
}

.profile--page .card-title .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.profile--page .card-title .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.profile--page .card-title .accordion-header {
  margin-bottom: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: 0;
}

.profile--page .card-title .accordion-button {
  padding: 0.2rem;
  background: #d74100;
  color: #ffffff;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
  box-shadow: none;
  border-radius: 0.3rem;
}

.profile--page .card-title .accordion-button svg, .profile--page .card-title .accordion-button svg * {
  stroke: #ffffff;
  margin: 0;
  width: 14px;
}

.profile--page .card-title .accordion-button.collapsed svg:last-child, .profile--page .card-title .accordion-button svg:first-child {
  display: none;
}

.profile--page .card-title .accordion-button svg:last-child, .profile--page .card-title .accordion-button.collapsed svg:first-child {
  display: block;
}

.profile--page .card-title .accordion-button:after {
  display: none;
}

.profile--page .accordion-body {
  background: none;
  margin-top: 1rem;
  padding: 0;
  border-radius: 0
}

.profile--page .accordion-body button {
  margin: 1rem 1rem 0 0;
  display: inline-block;
  padding: 0 1.5rem 0.1rem 1rem;
}

.profile--page .accordion-body .labelUpload {
  display: inline-block;
  cursor: pointer;
  padding: 0 1.5rem 0.1rem 1rem;
}

.profile--page .accordion-body .labelUpload svg {
  width: 22px;
  height: auto;
  vertical-align: text-top;
}

.profile--page .card-title .card-text {
  font-size: 15px;
  font-weight: normal;
}

.profile--page .accordion .accordion .accordion-header {
  position: relative;
  top: 0;
  right: 0;
  background: none;
  color: #141414;
}

.profile--page .accordion .accordion .accordion-header button {
  background: none;
  display: block;
  font-weight: 600;
  width: 100%;
  border-radius: 0;
  font-size: 16px;
  color: #141414;
  border-bottom: 1px solid #C4C4C4;
  padding: 0;
  padding-bottom: 0.8rem;
  height: auto;
  position: relative;
}

.profile--page .accordion .accordion .accordion-header button::after {
  display: inline-block;
  position: absolute;
  right: 0;
  background-size: 15px;
  top: 0.3rem;
  transition: all 0.3s ease-in-out;
}

.profile--page .accordion .accordion .accordion-header .accordion-button:not(.collapsed)::after {
  transform: var(--bs-accordion-btn-icon-transform);
  top: 0;
  right: 0.3rem;
}

.profile--page .accordion-body .form--check--input {
  display: flex;
  flex-flow: row wrap;
}

.profile--page .accordion-body .form-check {
  display: flex;
  flex: 1 0 100%;
  max-width: 50%;
  align-items: center;
  padding-left: 1.5em;
  margin-bottom: 0.8rem;
  font-size: 15px;
  font-weight: 400;
}

.profile--page .accordion-body .form-check-input {
  margin-right: 0.5rem;
}

.profile--page .text--orange {
  color: #e44300;
}

.profile--page .accordion .card .list-group-item {
  border: 1px solid #C4C4C4;
  padding: 1rem 1rem;
  margin: 0 0 1rem 0;
  color: #141414;
  font-size: 16px;
  min-width: 0;
  text-align: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background: none;
  width: 100%;
}

.profile--page .accordion .card .list-group-item:last-child {
  margin: 0;
}

.profile--page .accordion-body .list-group-item .form-check {
  display: flex;
  flex: 1 1;
  max-width: 100%;
  align-items: center;
  padding-left: 1.5em;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
}

.profile--page .accordion-body .rates--form label {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: normal;
  margin-top: 1rem;
  flex-flow: row wrap;
}

.profile--page .accordion-body .rates--form label .input-group {
  width: auto;
  flex: 1 1;
}

.profile--page .accordion-body .rates--form label span {
  margin-right: 1rem;
  min-width: 100px;
  display: flex;
  align-items: center;
}

.profile--page .feed--cards p.card-text pre {
  white-space: pre-wrap;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  color: #666666;
}

.profile--page .accordion-body .rates--form label .input-group-text {
  min-width: 0;
  margin: 0;
}

.profile--page .accordion-body .rates--form label input[type="checkbox"] {
  margin-right: 0.6rem;
  width: 16px;
  height: 16px;
}

.profile--page .accordion-body .btn-link, .profile--page .accordion-body .btn-link:active {
  color: inherit;
  padding: 0;
  margin: 0;
  font-size: 100%;
  line-height: normal;
  border: 0;
  background: none;
  border-radius: 0;
}

.redeem--modal .modal-header {
  padding: 1rem 2rem;
}

.redeem--modal .modal-body {
  max-width: 100%;
  padding: 1rem 2rem;
}

.redeem--modal .modal-dialog {
  max-width: 600px;
}

.profile--page .add--experience .list-group-item {
  padding: 0;
  border: 0;
}

.profile--page .add--experience .list-group-item .form-control {
  padding-right: 2rem;
}

.profile--page .add--experience .list-group-item span {
  position: absolute;
  right: 0.5rem;
}

.spinner--loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  font-size: 2rem;
}

table {
  border: 1px solid var(--bs-table-border-color);
}

table th, table td {
  padding: 0.5rem 1rem;
  text-align: center;
  border-width: 0 var(--bs-border-width);
}

table th:first-child, table td:first-child {
  text-align: left;
}




/*-==-==-=-=-=
Media Css-==
-=-=-=-=-=*/

@media screen and (min-width:801px) {
  .mobile--logo {
    display: none;
  }
}

@media screen and (min-width:1200px) {
  .menu--sidebar {
    max-width: 100%;
    width: 260px;
  }
  .section--content {
    width: 640px;
    max-width: 100%;
  }
  .rightFeed--sidebar {
    width: 300px;
    max-width: 100%;
  }

  .logged--sidebar {
    width: inherit;
  }

  .logged--screen .container,
  .Single-posts .container {
    max-width: 100%;
    padding: 0 3rem 0 2rem;
  }
}

@media (min-width:1100px) and (max-width:1199px) {

  .logged--screen .container,
  .Single-posts .container {
    max-width: 100%;
    padding: 0 2rem;
  }

  .sidebar--follow .card .btn-primary {
    margin: 0.5rem 0 0 auto;
  }

  .profile-img-follow img {
    width: 35px;
    height: 35px;
  }

  .logged--sidebar {
    width: 250px;
  }

  .fixed--sidebar, .sidebar--logOut {
    width: 250px;
  }
}

@media (min-width:801px) and (max-width:1099px) {

  .logged--screen .container,
  .Single-posts .container {
    max-width: 100%;
    padding: 0 1rem 0 2rem;
  }

  .logged--sidebar,
  .fixed--sidebar,
  .sidebar--logOut {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .logged--sidebar {
    padding: 2rem 1rem 0 0rem;
  }

  .sidebar--follow {
    margin-top: 2rem;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }

  .sidebar--follow .card .btn-primary {
    padding: 0.1rem 0.5rem;
    margin: 0.5rem 0 0 auto;
  }

  .profile-img-follow img {
    width: 35px;
    height: 35px;
  }

  .poll-img img {
    width: 20px;
    height: 20px;
  }

  .sidebar--follow h3 {
    padding: 0 0 0 1.2rem;
    font-size: 18px;
  }
}

@media screen and (max-width:800px) {
  .closemenu {
    display: block;
    position: absolute;
    z-index: 777777;
    right: 0;
    font-size: 30px;
    top: 8px;
  }

  .fixed--sidebar,
  .logged--sidebar,
  .sidebar--logOut {
    position: relative;
    height: auto;
    overflow-y: visible;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }


  .pro-sidebar>.pro-sidebar-inner {
    height: 100%;
    width: 80%;
    margin-left: auto;
    background: #ffffff;
    padding: 1rem;
  }

  .pro-sidebar {
    position: fixed;
    transition: all 0.5s ease-in-out;
    right: -100%;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    background: #33333370;
  }

  .pro-sidebar.collapsed {
    width: 100%;
    min-width: 100%;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    right: 0;
    background: #33333370;
  }

  .pro-sidebar.collapsed~.closemenu {
    position: fixed;
    right: 15px;
    top: 5px;
  }

  .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 0;
    padding: 0;
  }

  .singlePost--search {
    padding-bottom: 1rem;
    padding-top: 0rem;
  }

  .type--feed--post {
    border: 1px solid #21212120;
    border-bottom: 0;
    margin-top: 1rem;
  }

  .text-comment {
    padding-left: 0;
  }

  .sidebar--follow {
    margin-top: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }

  .ideas--logo {
    padding: 0 1.2rem;
  }

  .mobile--logo {
    padding: 1rem 1.2rem 0;
    text-align: center;
  }

  .post--search .form-group {
    margin-bottom: 0;
  }

  .logged--sidebar .card {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .sidebar--follow .card {
    margin: 0;
  }

  .feed--listing {
    border-width: 0;
    padding: 1rem 0 0 !important;
  }

  form.post--search {
    padding-top: 0;
    max-width: 100%;
  }

  .out--with--bottom {
    padding-top: 1rem;
    padding-bottom: 80px;
  }

  .out--with--bottom form.post--search {
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  .sidebar--Login {
    display: none;
  }

  .modal-body {
    max-width: 90%;
  }

  .modal-content {
    width: 99%;
    overflow-y: auto;
    max-height: 600px;
    height: 100%;
  }
}

@media screen and (max-width:575px) {
  .profile--box .card {
    padding: 1rem;
    border-radius: 5px;
    border-left: 0;
    border-right: 0;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
    background: #21212108;
    margin-top: 2rem;
  }

  .ideas--logo img {
    margin-left: 0;
  }

  .ideas--logo .logo--full {
    max-width: 150px;
  }
}